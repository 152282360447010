require('@rails/ujs').start();
require('turbolinks').start();

import Swiper from 'swiper';

document.addEventListener('turbolinks:load', () => {
  const scrollToCurrent = () => {
    // скролл до текущего времени
    let current_program = document.getElementById('current_program')
    if (current_program) {
      current_program.scrollIntoView({block: 'center', behavior: 'smooth'});
    }
  };
  
  const handleTabs = () => {
    const tabs = document.querySelector('.tabs')
    const tabsContent = document.querySelector('.tabs__content');
    const tabsShowBtn = document.querySelector('.tabs-show-btn');
    const tabsHideBtn = document.querySelector('.tabs__hide-btn');
    
    if (tabs && tabsHideBtn && tabsShowBtn) {
      tabsShowBtn.addEventListener('click', () => {
        if (tabs.classList.contains('show')) {
          tabs.classList.remove('show');
        } else {
          tabs.classList.add('show');
          scrollToCurrent();
        }
      });
      
      tabsHideBtn.addEventListener('click', () => {
        tabs.classList.remove('show');
      });
    }
    
    if (tabs && tabsContent) {
      const tabsBtns = tabs.querySelectorAll('.tabs__nav-btn');
      
      const tabsPanes = tabsContent.querySelectorAll('.tabs__pane');
      
      tabsBtns.forEach((btn) =>
        btn.addEventListener('click', () => {
          tabsPanes.forEach((pane) => pane.classList.remove('active'));
          tabsBtns.forEach((btn) => btn.classList.remove('active'));
          
          const targetId = btn.getAttribute('data-target');
          
          btn.classList.toggle('active');
          tabsContent
            .querySelector(`${targetId}`)
            .classList.toggle('active');
          
          if (btn.dataset.isToday) {
            scrollToCurrent();
          }
        }),
      )
    }
  }
  handleTabs();
  
  const animationSlideLeft = () => {
    const animatedElList = document.querySelectorAll(
      '.slideLeft, .decor-program',
    );
    
    if (animatedElList.length) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            entry.target.classList.toggle('show', entry.isIntersecting)
            
            if (entry.isIntersecting) observer.unobserve(entry.target)
          })
        },
        {
          threshold: 0.2,
        },
      );
      
      animatedElList.forEach((animatedEl) => {
        observer.observe(animatedEl);
      });
    }
  }
  animationSlideLeft();
  
  const checkProjectImageSize = () => {
    const projectImage = document.querySelector('.project-image');
    const sectionList = document.querySelectorAll('.section');
    
    if (projectImage && sectionList.length) {
      projectImage.addEventListener('load', (event) => {
        const {naturalWidth, naturalHeight} = projectImage;
        
        if (naturalHeight > naturalWidth) {
          sectionList.forEach((section) => section.classList.add('vert'));
        }
      });
    }
  }
  checkProjectImageSize();
  
  // Carousel
  const swiper = new Swiper('.swiper--watch', {
    direction: 'horizontal',
    
    breakpoints: {
      0: {
        slidesPerView: 1.3,
        spaceBetween: 8,
      },
      
      576: {
        slidesPerView: 2,
        spaceBetween: 12,
      },
      
      992: {
        slidesPerView: 2.4,
        spaceBetween: 20,
      },
    },
    
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
  
  $('.swiper-button-next').off('click').on('click', function () {
    swiper.slideNext();
  });
  $('.swiper-button-prev').off('click').on('click', function () {
    swiper.slidePrev();
  });
  
  $('.video-btn').off('click').on('click', function (e) {
    e.preventDefault();
    let $t = $(this), video = $t.next('video').get(0);
    if (video.playing) {
      video.pause();
      $t.removeClass('video-is-playing');
    } else {
      video.play();
      $t.addClass('video-is-playing');
    }
  });
  
  let changeVideoSize = function () {
    $('.section__video-wrapper iframe').each(function () {
      let $t = $(this);
      $t.css({
        height: 9 * $t.width() / 16
      });
    });
  };
  changeVideoSize();
  $(window).on('resize', changeVideoSize);
  
  // Preload Images
  const preload = src => new Promise(function (resolve, reject) {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    }
    img.onerror = reject;
    img.src = src;
  });
  
  const preloadAll = function (sources) {
    return Promise.all(sources.map(preload));
  };
});

document.addEventListener("turbolinks:before-cache", function () {
});

document.addEventListener("turbolinks:request-end", function () {
  if ($('video').length > 0) {
    $('video').remove();
  }
});

Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
  get: function () {
    return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
  }
});
